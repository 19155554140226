<template>
	<w-menu ref="menu" v-model="menu" :close-on-content-click="false" lazy offset-y transition="scale-transition" full-width min-width="290px">
		<template v-if="$scopedSlots.activator" v-slot:activator="scope"><slot name="activator" v-bind="scope"></slot></template>
		<template v-else v-slot:activator="{ on }">
			<w-flex shrink>
				<w-text-input
					v-model="dateWritten"
					v-bind="$attrs"
					:color="colorField"
					:clearable="readonly === false && clearable"
					:disabled="disabled"
					:label="label"
					prepend-icon="event"
					readonly
					:required="required"
					:rules="rulesComputed"
					:style="small ? `max-width:120px;min-width:120px` : 'min-width:120px'"
					:width="textInputWidth"
					v-on="readonly ? undefined : on"
					@click:clear="clearDate"
					@keypress="keypress"
					@keyup="keyup"
				/>
			</w-flex>
		</template>
		<v-date-picker
			ref="picker"
			v-model="model"
			:allowed-dates="allowedDatesMethod"
			:color="colorField"
			:first-day-of-week="$firstDayOfTheWeek()"
			:header-color="colorField"
			:locale="$i18n.locale"
			scrollable
			:readonly="readonly !== false"
			:min="dateSelectedMin"
			:max="dateSelectedMax"
			@change="pickDate"
		>
			<v-spacer></v-spacer>
			<w-btn flat @click="menu = false">{{ $t('actions.close') }}</w-btn>
		</v-date-picker>
	</w-menu>
</template>

<script>
const CLEARABLE_WIDTH = 20
const MINIMUM_INPUT_WIDTH = 120

export default {
	name: 'WDatePicker',
	props: {
		allowedDates: {
			required: false,
			type: Function,
			default: undefined
		},
		birthday: {
			required: false,
			type: Boolean,
			default: false
		},
		clearable: {
			required: false,
			type: Boolean,
			default: true
		},
		colorField: {
			required: false,
			type: String,
			default: 'primary'
		},
		disabled: {
			required: false,
			type: Boolean,
			default: false
		},
		label: {
			required: false,
			type: String,
			default: null
		},
		max: {
			default: undefined,
			required: false,
			type: String,
			validator: function (value) {
				return new Date(value).toString() !== 'Invalid Date'
			}
		},
		min: {
			default: undefined,
			required: false,
			type: String,
			validator: function (value) {
				return new Date(value).toString() !== 'Invalid Date'
			}
		},
		openOnFocus: {
			default: false,
			required: false,
			type: Boolean
		},
		readonly: {
			required: false,
			type: Boolean,
			default: false
		},
		required: {
			required: false,
			type: Boolean,
			default: false
		},
		rules: {
			required: false,
			type: Array,
			default: () => []
		},
		shrink: {
			default: false,
			required: false,
			type: Boolean
		},
		small: {
			default: false,
			required: false,
			type: Boolean
		},
		value: {
			required: false,
			type: String,
			default: ''
		}
	},
	data: function () {
		return {
			dateSelected: null,
			dateWritten: null,
			menu: false
		}
	},
	computed: {
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		dateSelectedMax: function () {
			let result
			if (this.birthday) {
				result = new Date()
				result.setFullYear(result.getFullYear() - 10)
				result = result.toISOString().substr(0, 10)
			}
			return result
		},
		dateSelectedMin: function () {
			let result
			if (this.birthday) {
				result = new Date()
				result.setFullYear(result.getFullYear() - 90)
				result = result.toISOString().substr(0, 10)
			}
			return result
		},
		rulesComputed: function () {
			return this.rules.map(item => {
				return item(this.model)
			})
		},
		textInputWidth: function () {
			if (!this.shrink) {
				return undefined
			}
			let result = MINIMUM_INPUT_WIDTH
			if (this.clearable) {
				result += CLEARABLE_WIDTH
			}
			return `${result}px`
		}
	},
	watch: {
		value: {
			immediate: true,
			handler: function (val) {
				this.model = val
				val ? (this.dateWritten = this.$translateDateTime(val, 'tiny', true)) : (this.dateWritten = null)
			}
		},
		menu: {
			immediate: true,
			handler: function (val) {
				if (this.birthday) {
					val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
				}
			}
		}
	},
	methods: {
		allowedDatesMethod: function (value) {
			if (this.allowedDates !== undefined && !this.allowedDates(value)) {
				return false
			}

			const date = new Date(value)
			if (this.max) {
				const maxDate = new Date(this.max)
				if (maxDate.getTime() < date.getTime()) {
					return false
				}
			}

			if (this.min) {
				const minDate = new Date(this.min)
				if (minDate.getTime() > date.getTime()) {
					return false
				}
			}

			return true
		},
		clearDate: function () {
			this.model = null
			this.dateWritten = null
			this.$emit('clear')
			this.$emit('input', null)
		},
		keypress: function (event) {
			this.menu = false
			this.model = null
			let len
			this.dateWritten ? (len = this.dateWritten.length) : (len = 0)
			// add only integer and '/'
			if (event.keyCode < 47 || event.keyCode > 57 || len > 9) {
				event.preventDefault()
			} else {
				// If we're at a particular place, let the user type the slash
				if ((len !== 1 || len !== 3) && event.keyCode == 47) {
					event.preventDefault()
				}
				// If they don't add the slash, do it for them...
				if (len === 2 || len === 5) {
					this.dateWritten += '/'
				}
			}
		},
		keyup: function (event) {
			if (event.key === 'Tab') {
				this.menu = true
			}
			let len
			this.dateWritten ? (len = this.dateWritten.length) : (len = 0)
			if (len > 9) {
				let dateTimeParts = this.dateWritten.split('/')
				this.model = dateTimeParts[2] + '-' + dateTimeParts[1] + '-' + dateTimeParts[0]
			} else if (len == 0) {
				this.model = null
			}
		},
		pickDate: function (date) {
			this.model = date
			this.dateWritten = this.$translateDateTime(this.model, 'tiny', true)
			this.menu = false
		}
	}
}
</script>
